import React from 'react'
import { Helmet } from 'react-helmet'
import { PageLayout } from '../layouts'
import * as styles from '../styles/pages/thank-you'
import heart from '../../assets/icons/heart.svg'


export default () => (
  <PageLayout
    sharingTitle="Aito.ai - Thank you"
    description="Thank you for your interest. We'll be in touch soon"
  >
    <Helmet>
      <title>Aito.ai - Thank you</title>
    </Helmet>
    <styles.Container>
      <styles.Centerer>
        <styles.Heart src={heart} />
      </styles.Centerer>
      <styles.Centerer>
        <styles.Header>
          Thank you for
          <br />
          your interest
        </styles.Header>
      </styles.Centerer>
      <styles.Centerer>
        <styles.Text>We&#39;ll be in touch soon</styles.Text>
      </styles.Centerer>
    </styles.Container>
  </PageLayout>
)
