import styled from 'styled-components'
import variables from '../variables'
import { headerStyles } from '../global'
import { Heading } from '../../components'

export const Container = styled.div`
  width: 100%;
  padding: 150px;
  background: ${variables.colors.silverTree.darker};
`
export const Centerer = styled.div`
  display: flex;
  justify-content: center;
`

export const Header = styled(Heading)`
  ${headerStyles.headerSmall}
  text-align: center;
  color: white;
`

export const Heart = styled.img`
  height: 100px;
  margin-bottom: 30px;
`

export const Text = styled.p`
  color: white;
`
